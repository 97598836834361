import React, { useState } from 'react';
import { usePlanningsQuery, useUpdatePlanningMutation } from '../../generated/graphql';
import { useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import CreatePlanning from '../CreatePlanning/CreatePlanning';
import CustomModal from '../CustomModal/CustomModal';
import Loading from '../Loading/Loading';
import PlanningPreview from '../PlanningPreview/PlanningPreview';

const PlanningsView = () => {

    useGuestRedirection();

    const [isModalOpen, setModalOpen] = useState(false);
    const { loading, data, error } = usePlanningsQuery()
    const [updatePlanning] = useUpdatePlanningMutation()

    useGlobalFab(() => setModalOpen(true));

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p> Error</p>
    }

    if (!data?.plannings) {
        return <p>Empty</p>
    }

    const onComplete = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setModalOpen(false);
    };

    const update = (planning: any, state: any) => {
        updatePlanning({ variables: { id: planning.id, state: state } });
    };

    const results = data.plannings;

    return (
        <>
            {results.map((planning: any) => <PlanningPreview key={planning.id} planning={planning} update={update} />)}
            <CustomModal isActive={isModalOpen} title="Create Planning" handleClose={() => setModalOpen(false)}>
                <CreatePlanning onComplete={onComplete} />
            </CustomModal>
        </>
    );
};

export default PlanningsView;
