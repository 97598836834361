import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styles from '../CustomModal/CustomModal.module.scss';

const CustomModal = ({ isActive, children, title, handleClose, showClose = true }: { isActive?: boolean, children: any, title?: string, handleClose: any, showClose?: boolean }) => {
    const fullWidth = true;
    const maxWidth = 'sm';
    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            onClose={handleClose}
            className={styles.CustomModal}
            open={isActive || false}
        >
            <DialogTitle>{title}</DialogTitle>
            {
                showClose &&
                <IconButton
                    className={styles.CloseAction}
                    aria-label="close"
                    onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            }
            <DialogContent onClick={event => event.stopPropagation()}>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default CustomModal;
