import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useRecurrentTaskQuery, useUpdateRecurrentTaskMutation } from '../../generated/graphql';
import { useGuestRedirection } from '../../services/Hooks';
import styles from './RecurrentTask.module.scss';

const RecurrentTaskInput = (props: any) => {
    let { name, type, defaults, handle } = props;

    if (!type) {
        type = 'text';
    }

    return (
        <FormControl fullWidth>
            <TextField
                margin="dense"
                id={name}
                name={name}
                label={name}
                defaultValue={defaults}
                onChange={handle}
                type={type}
                required
            />
        </FormControl>
    );
};

const RecurrentTask = (props: any) => {

    useGuestRedirection();

    const id = parseInt(props.match.params.id);
    const { loading, error, data } = useRecurrentTaskQuery({ variables: { id } })
    const [open, setOpen] = useState(false);
    const [updateRecurrentTask] = useUpdateRecurrentTaskMutation({ onCompleted: () => setOpen(true) });

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error || !data?.recurrent_task) {
        return <p>Error</p>;
    }

    const handleSubmit = () => {
        updateRecurrentTask({ variables: { id, ...data } });
    };

    const handleInput = (event: any) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        if (name === 'estimate') {
            // @ts-ignore
            data[name] = parseInt(value);
        } else {
            // @ts-ignore
            data[name] = value;
        }
    };

    const { name, description, estimate } = data.recurrent_task;

    return (
        <Card className={styles.RecurrentTask}>
            <CardContent>
                <RecurrentTaskInput name="name" defaults={name} handle={handleInput} />
                <RecurrentTaskInput name="description" defaults={description} handle={handleInput} />
                <RecurrentTaskInput name="estimate" type="number" defaults={estimate} handle={handleInput} />
                <Button color="primary" onClick={handleSubmit}>Update Recurrent Task</Button>
            </CardContent>
            <Snackbar
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                key={Math.random()}
                open={open}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                message="Recurrent Task Updated"
            />
        </Card>
    );
};

export default RecurrentTask;
