import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import NotesIcon from '@material-ui/icons/Notes';
import FlagIcon from '@material-ui/icons/OutlinedFlag';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DELETE_PLANNING_TASK, PLANNING_TASKS, UPDATE_PLANNING, UPDATE_PLANNING_TASK } from '../../services/Queries';
import CreatePlanning from '../CreatePlanning/CreatePlanning';
import CustomModal from '../CustomModal/CustomModal';
import ModalForm from '../ModalForm/ModalForm';
import styles from './PlanningTable.module.scss';

const AllocationStats = ({ planning }: { planning: any }) => {
    const allocPerc = planning.allocated / planning.max_allocation * 100;
    const execPerc = planning.executed / planning.allocated * 100;
    return (
        <>
            <div className={styles.AllocationStats}>
                Allocated {planning.allocated} of {planning.max_allocation} ({allocPerc.toFixed(2)}%)
          </div>
            {planning.state !== 'DRAFT' &&
                <div className={styles.AllocationStats}>
                    Executed {planning.executed} of {planning.allocated} ({execPerc.toFixed(2)}%)
           </div>
            }
            <div className={styles.AllocationStats}>
                Goal Completion {planning.goal_completion}%
          </div>
        </>
    );
};

const PlanningTaskState = (props: any) => {
    const [updatePlanningTask] = useMutation(UPDATE_PLANNING_TASK);
    const { id, state } = props.planningTask;
    let nextState: any = null;
    let stateIcon = null;

    const updateState = (nextState: any) => {
        updatePlanningTask({ variables: { id, state: nextState } });
    };

    switch (state) {
        case 'CREATED':
            stateIcon = <PlayArrowIcon />;
            nextState = 'BLOCKED';
            break;
        case 'BLOCKED':
            stateIcon = <BlockIcon />;
            nextState = 'FINISHED';
            break;
        case 'FINISHED':
            stateIcon = <DoneIcon />;
            nextState = 'CREATED';
            break;
        default:
            stateIcon = null;
            nextState = null;
            break;
    }
    return (
        <Button variant="text" color="primary" size="small" onClick={() => updateState(nextState)}>
            <SvgIcon>
                {stateIcon}
            </SvgIcon>
        </Button>
    );
};

const PlanningTaskObservations = (props: any) => {
    let element = null;

    if (props.observations) {
        element = (
            <Button>
                <Tooltip title={props.observations}>
                    <SvgIcon>
                        <NotesIcon />
                    </SvgIcon>
                </Tooltip>
            </Button>
        );
    }
    return element;
};

const PlanningTask = ({ planning_task, index, edit, bookmark, refetch, goal }: { planning_task: any, index: any, edit: any, bookmark: any, refetch: any, goal: any }) => {

    const [deletePlanningTask] = useMutation(DELETE_PLANNING_TASK, { onCompleted: refetch });

    const delete_ = (planning_task: any) => {
        deletePlanningTask({ variables: { id: planning_task.id } });
    };

    const cls = planning_task.is_bookmarked ? styles.Bookmarked : '';

    return (
        <tr className={cls}>
            <td>
                {index + 1}
            </td>
            <td>
                <Link to={'/task/' + planning_task.task.id}>
                    {planning_task.task.name}
                </Link>
            </td>
            <td>
                {planning_task.task.project.name}
            </td>
            <td className={styles.Centered}>
                {planning_task.allocated}
            </td>
            <td className={styles.Centered}>
                {planning_task.executed}
            </td>
            <td className={styles.Centered}>
                {planning_task.allocated - planning_task.executed}
            </td>
            <td className={styles.Centered}>
                {planning_task.is_goal ? planning_task.priority : '-'}
            </td>
            <td>
                <PlanningTaskObservations observations={planning_task.observations} />
            </td>
            <td>
                <PlanningTaskState planningTask={planning_task} />
            </td>
            <td>

                <Button variant="text" color="primary" size="small" onClick={() => delete_(planning_task)}>
                    <SvgIcon>
                        <DeleteIcon />
                    </SvgIcon>
                </Button>
                <Button variant="text" color="primary" size="small" onClick={() => edit(planning_task)}>
                    <SvgIcon>
                        <EditIcon />
                    </SvgIcon>
                </Button>
                <Button variant="text" color="primary" size="small" onClick={() => bookmark(planning_task)}>
                    <SvgIcon>
                        <BookmarkIcon />
                    </SvgIcon>
                </Button>
                <Button variant="text" color="primary" size="small" onClick={() => goal(planning_task)}>
                    <SvgIcon>
                        <FlagIcon className={classNames({ [styles.Selected]: planning_task.is_goal })} />
                    </SvgIcon>
                </Button>
            </td>
        </tr>
    );
};

const AddPlanningTask = (props: any) => (
    <Button variant="text" color="primary" size="small" onClick={() => props.set(!props.visible)}>
        <SvgIcon>
            <AddIcon />
        </SvgIcon>
    </Button>
);

const HideFinished = (props: any) => {
    const element = props.stateIn === "CREATED,BLOCKED,FINISHED" ? <VisibilityOffIcon /> : <VisibilityIcon />;
    return (
        <Button variant="text" color="primary" size="small" onClick={props.setStateIn}>
            <SvgIcon>
                {element}
            </SvgIcon>
        </Button>
    );
};

const UpdatePlanning = (props: any) => {

    return (
        <Button variant="text" color="primary" size="small" onClick={props.open}>
            <SvgIcon>
                <EditIcon />
            </SvgIcon>
        </Button>
    );
};

const PlanningToolbar = (props: any) => (
    <Grid container justify="center">
        <UpdatePlanning open={props.open} />
        <AddPlanningTask visible={props.visible} set={props.set} />
        <HideFinished stateIn={props.stateIn} setStateIn={props.setStateIn} />
    </Grid>
);


const PlanningHeader = ({ planning }: { planning: any }) => (
    <h3 className={styles.PlanningWeek}>
        Semana #{planning.week} {planning.year} {planning.state}
    </h3>
);

const PlanningNotes = ({ planning, update, loading }: { planning: any, update: any, loading: any }) => {

    const AUTOSAVE_INTERVAL = 1500;
    const [lastData, setLastData] = useState(null);
    const [data, setData] = useState(null);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const timer = window.setTimeout(() => {
            if (lastData !== data) {
                // @ts-ignore
                update({ notes: data.notes });
                setLastData(data);
            }
        }, AUTOSAVE_INTERVAL);
        return () => window.clearTimeout(timer);
    }, [data]);

    const changeHandler = (event: any) => {
        // @ts-ignore
        setData({ ...data, [event.target.name]: event.target.value });
    };

    if (planning && data === null) {
        setData(planning);
    }

    return (
        <div>
            <p className={styles.Saving}>{loading ? '...saving' : 'all saved'}</p>
            <TextField
                name="notes"
                label="notes"
                type="text"
                multiline
                // @ts-ignore
                value={data !== null ? data.notes : ''}
                onChange={changeHandler}
            />
        </div>
    );
};

export const ExpandedPlanning = (props: any) => {
    const planning_id = parseInt(props.planning.id);
    const [visible, setVisible] = useState(false);
    const [lobo, setLobo] = useState();
    const [stateIn, setStateIn] = useState("CREATED,BLOCKED,FINISHED");
    const { loading, error, data, refetch } = useQuery(PLANNING_TASKS, { variables: { planning: planning_id, state_In: stateIn } });
    const [isModalOpen, setModalOpen] = useState(false);
    const [updatePlanningTask] = useMutation(UPDATE_PLANNING_TASK);
    const [updatePlanning, { loading: updatePlanningLoading }] = useMutation(UPDATE_PLANNING);

    const edit = (pt: any) => {
        setLobo(pt);
        setVisible(!visible);
    };

    const close = () => {
        // @ts-ignore
        setLobo();
        setVisible(false);
        refetch();
    };

    const toogleStateIn = (states: any) => {
        if (stateIn === "CREATED,BLOCKED,FINISHED") {
            setStateIn("CREATED,BLOCKED");
        } else {
            setStateIn("CREATED,BLOCKED,FINISHED");
        }
    };

    const openUpdate = () => {
        setModalOpen(true);
    };

    const bookmark = (planning_task: any) => {
        updatePlanningTask({ variables: { id: planning_task.id, is_bookmarked: !planning_task.is_bookmarked } });
    };

    const goal = (planning_task: any) => {
        updatePlanningTask({ variables: { id: planning_task.id, is_goal: !planning_task.is_goal } });
    };

    const update = (data: any) => {
        updatePlanning({ variables: { id: props.planning.id, ...data } });
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error</p>;
    }

    const headers = [
        '#', 'Name', 'Project',
        'Planned', 'Executed', 'Remaining',
        'Priority', 'Observations', 'State', 'Actions'
    ];

    return (
        <Grid container>
            <Grid item xs={8}>
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    <PlanningHeader planning={props.planning} />
                    <AllocationStats planning={props.planning} />
                    <table className={styles.PlanningTable}>
                        <tbody>
                            <tr>
                                {headers.map(el => <th key={Math.random()}>{el}</th>)}
                            </tr>
                            {data.planning_tasks.map(
                                (planning_task: any, index: any) => <PlanningTask key={planning_task.id} planning_task={planning_task} index={index} edit={edit} refetch={refetch} bookmark={bookmark} goal={goal} />
                            )}
                        </tbody>
                    </table>
                    <ModalForm visible={visible} close={close} planning={props.planning} pt={lobo} />
                    <CustomModal isActive={isModalOpen} title="Update Planning" handleClose={() => setModalOpen(false)}>
                        <CreatePlanning id={planning_id} onComplete={() => setModalOpen(false)} />
                    </CustomModal>
                    <PlanningToolbar visible={visible} set={setVisible} stateIn={stateIn} setStateIn={toogleStateIn} open={openUpdate} />
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <PlanningNotes planning={props.planning} update={update} loading={updatePlanningLoading} />
            </Grid>
        </Grid>
    );
};

const PlanningTable = ({ planning }: { planning: any }) => {
    return (
        <Grid container justify="center">
            <ExpandedPlanning planning={planning} />
        </Grid>
    );
};

export default PlanningTable;
