import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Datetime: any;
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  plannings?: Maybe<Array<Maybe<Planning>>>;
  planning_task?: Maybe<PlanningTask>;
  planning_tasks?: Maybe<Array<Maybe<PlanningTask>>>;
  project?: Maybe<Project>;
  projects?: Maybe<Array<Maybe<Project>>>;
  active_projects?: Maybe<Array<Maybe<Project>>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  task?: Maybe<Task>;
  current_planning?: Maybe<Planning>;
  getPlanning: Planning;
  get_goals?: Maybe<Goals>;
  recurrent_tasks?: Maybe<Array<Maybe<RecurrentTask>>>;
  recurrent_task?: Maybe<RecurrentTask>;
};


export type QueryPlanning_TasksArgs = {
  planning?: Maybe<Scalars['Int']>;
  state_In?: Maybe<Scalars['String']>;
};


export type QueryProjectArgs = {
  pk: Scalars['Int'];
};


export type QueryProjectsArgs = {
  state?: Maybe<Scalars['String']>;
  sort?: Maybe<ProjectSort>;
};


export type QueryActive_ProjectsArgs = {
  sort?: Maybe<ProjectSort>;
};


export type QueryTasksArgs = {
  project?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['Int']>;
  state_In?: Maybe<Scalars['String']>;
};


export type QueryTaskArgs = {
  id: Scalars['Int'];
};


export type QueryGetPlanningArgs = {
  id: Scalars['ID'];
};


export type QueryGet_GoalsArgs = {
  page?: Maybe<Scalars['Int']>;
  page_size?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  full_search?: Maybe<Scalars['String']>;
  state?: Maybe<GoalState>;
};


export type QueryRecurrent_TaskArgs = {
  id: Scalars['Int'];
};

export type Planning = {
  __typename?: 'Planning';
  id: Scalars['Int'];
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  allocated?: Maybe<Scalars['Int']>;
  executed?: Maybe<Scalars['Int']>;
  max_allocation?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  planning_tasks?: Maybe<Array<Maybe<PlanningTask>>>;
  goal_completion: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  evaluation?: Maybe<Scalars['String']>;
};

export type PlanningTask = {
  __typename?: 'PlanningTask';
  id: Scalars['Int'];
  allocated?: Maybe<Scalars['Int']>;
  executed?: Maybe<Scalars['Int']>;
  task: Task;
  planning: Planning;
  state: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  is_bookmarked: Scalars['Boolean'];
  is_goal: Scalars['Boolean'];
  priority?: Maybe<Scalars['Int']>;
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  description?: Maybe<Scalars['String']>;
  user: User;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['Int'];
  name: Scalars['String'];
  order: Scalars['Int'];
  state: Scalars['String'];
  created: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  created: Scalars['Datetime'];
  updated: Scalars['Datetime'];
};


export enum ProjectSort {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export enum GoalState {
  Created = 'CREATED',
  Committed = 'COMMITTED',
  Completed = 'COMPLETED'
}

export type Goals = {
  __typename?: 'Goals';
  results?: Maybe<Array<Maybe<Goal>>>;
  previous?: Maybe<Scalars['Boolean']>;
  next?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['Int']>;
};

export type Goal = {
  __typename?: 'Goal';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['Datetime']>;
  commit_date?: Maybe<Scalars['Datetime']>;
  completed_date?: Maybe<Scalars['Datetime']>;
  state: GoalState;
  observations?: Maybe<Scalars['String']>;
  deliverable?: Maybe<Scalars['String']>;
  created: Scalars['Datetime'];
  updated: Scalars['Datetime'];
};

export type RecurrentTask = {
  __typename?: 'RecurrentTask';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  recurrence?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  user?: Maybe<User>;
  estimate?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  notify?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticate: AuthenticateResponse;
  create_goal?: Maybe<Goal>;
  createPlanning?: Maybe<Planning>;
  updatePlanning?: Maybe<Planning>;
  createProject: Scalars['String'];
  deleteProject: Scalars['String'];
  createPlanningTask: PlanningTask;
  updatePlanningTask: PlanningTask;
  deletePlanningTask: Scalars['String'];
  updateRecurrentTask: RecurrentTask;
  updateGoal?: Maybe<Goal>;
};


export type MutationAuthenticateArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationCreate_GoalArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['Datetime']>;
  commit_date?: Maybe<Scalars['Datetime']>;
};


export type MutationCreatePlanningArgs = {
  week: Scalars['Int'];
  year: Scalars['Int'];
  type: Scalars['String'];
  max_allocation: Scalars['Int'];
};


export type MutationUpdatePlanningArgs = {
  id: Scalars['ID'];
  state?: Maybe<PlanningState>;
  max_allocation?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  evaluation?: Maybe<Scalars['String']>;
};


export type MutationCreateProjectArgs = {
  name: Scalars['String'];
};


export type MutationDeleteProjectArgs = {
  id: Scalars['Int'];
};


export type MutationCreatePlanningTaskArgs = {
  allocated: Scalars['Int'];
  task: Scalars['Int'];
  planning: Scalars['Int'];
  observations?: Maybe<Scalars['String']>;
};


export type MutationUpdatePlanningTaskArgs = {
  id: Scalars['Int'];
  allocated?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  is_bookmarked?: Maybe<Scalars['Boolean']>;
  is_goal?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
};


export type MutationDeletePlanningTaskArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateRecurrentTaskArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  estimate?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateGoalArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['Datetime']>;
  commit_date?: Maybe<Scalars['Datetime']>;
  completed_date?: Maybe<Scalars['Datetime']>;
  observations?: Maybe<Scalars['String']>;
  deliverable?: Maybe<Scalars['String']>;
  state?: Maybe<GoalState>;
};

export type AuthenticateResponse = ValidationError | AuthenticatePayload;

export type ValidationError = {
  __typename?: 'ValidationError';
  message: Scalars['String'];
};

export type AuthenticatePayload = {
  __typename?: 'AuthenticatePayload';
  token: Scalars['String'];
  user: User;
};

export enum PlanningState {
  Draft = 'DRAFT',
  Approved = 'APPROVED',
  Finished = 'FINISHED'
}


export type GoalAttributesFragment = (
  { __typename?: 'Goal' }
  & Pick<Goal, 'id' | 'name' | 'description' | 'state' | 'commit_date' | 'completed_date' | 'deadline' | 'observations' | 'deliverable' | 'created' | 'updated'>
);

export type PlanningAttributesFragment = (
  { __typename?: 'Planning' }
  & Pick<Planning, 'id' | 'week' | 'year' | 'allocated' | 'executed' | 'max_allocation' | 'state' | 'goal_completion' | 'notes' | 'evaluation'>
);

export type Create_GoalMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['Datetime']>;
  commit_date?: Maybe<Scalars['Datetime']>;
}>;


export type Create_GoalMutation = (
  { __typename?: 'Mutation' }
  & { create_goal?: Maybe<(
    { __typename?: 'Goal' }
    & GoalAttributesFragment
  )> }
);

export type CreatePlanningMutationVariables = Exact<{
  week: Scalars['Int'];
  year: Scalars['Int'];
  type: Scalars['String'];
  max_allocation: Scalars['Int'];
}>;


export type CreatePlanningMutation = (
  { __typename?: 'Mutation' }
  & { createPlanning?: Maybe<(
    { __typename?: 'Planning' }
    & Pick<Planning, 'id' | 'week' | 'year' | 'type' | 'max_allocation'>
  )> }
);

export type CreatePlanningTaskMutationVariables = Exact<{
  allocated: Scalars['Int'];
  task: Scalars['Int'];
  planning: Scalars['Int'];
  observations?: Maybe<Scalars['String']>;
}>;


export type CreatePlanningTaskMutation = (
  { __typename?: 'Mutation' }
  & { createPlanningTask: (
    { __typename?: 'PlanningTask' }
    & Pick<PlanningTask, 'id' | 'allocated' | 'executed'>
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'name' | 'state'>
      & { project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )> }
    ), planning: (
      { __typename?: 'Planning' }
      & Pick<Planning, 'id'>
    ) }
  ) }
);

export type UpdateGoalMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['Datetime']>;
  commit_date?: Maybe<Scalars['Datetime']>;
  completed_date?: Maybe<Scalars['Datetime']>;
  observations?: Maybe<Scalars['String']>;
  deliverable?: Maybe<Scalars['String']>;
  state?: Maybe<GoalState>;
}>;


export type UpdateGoalMutation = (
  { __typename?: 'Mutation' }
  & { updateGoal?: Maybe<(
    { __typename?: 'Goal' }
    & GoalAttributesFragment
  )> }
);

export type UpdatePlanningTaskMutationVariables = Exact<{
  id: Scalars['Int'];
  allocated?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  is_bookmarked?: Maybe<Scalars['Boolean']>;
  is_goal?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
}>;


export type UpdatePlanningTaskMutation = (
  { __typename?: 'Mutation' }
  & { updatePlanningTask: (
    { __typename?: 'PlanningTask' }
    & Pick<PlanningTask, 'id' | 'allocated' | 'state' | 'observations' | 'is_bookmarked' | 'is_goal' | 'priority'>
  ) }
);

export type DeletePlanningTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePlanningTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlanningTask'>
);

export type GetGoalsQueryVariables = Exact<{
  state?: Maybe<GoalState>;
  fullSearch?: Maybe<Scalars['String']>;
}>;


export type GetGoalsQuery = (
  { __typename?: 'Query' }
  & { get_goals?: Maybe<(
    { __typename?: 'Goals' }
    & Pick<Goals, 'count' | 'next' | 'previous'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Goal' }
      & GoalAttributesFragment
    )>>> }
  )> }
);

export type GetPlanningQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPlanningQuery = (
  { __typename?: 'Query' }
  & { getPlanning: (
    { __typename?: 'Planning' }
    & PlanningAttributesFragment
  ) }
);

export type PlanningsQueryVariables = Exact<{ [key: string]: never; }>;


export type PlanningsQuery = (
  { __typename?: 'Query' }
  & { plannings?: Maybe<Array<Maybe<(
    { __typename?: 'Planning' }
    & PlanningAttributesFragment
  )>>> }
);

export type PlanningTasksQueryVariables = Exact<{
  planning?: Maybe<Scalars['Int']>;
  state_In?: Maybe<Scalars['String']>;
}>;


export type PlanningTasksQuery = (
  { __typename?: 'Query' }
  & { planning_tasks?: Maybe<Array<Maybe<(
    { __typename?: 'PlanningTask' }
    & Pick<PlanningTask, 'id' | 'allocated' | 'executed' | 'state' | 'observations' | 'is_bookmarked' | 'is_goal' | 'priority'>
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'name' | 'state'>
      & { project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )> }
    ) }
  )>>> }
);

export type ProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<Array<Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'state'>
  )>>> }
);

export type ActiveProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveProjectsQuery = (
  { __typename?: 'Query' }
  & { active_projects?: Maybe<Array<Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'state'>
  )>>> }
);

export type TasksQueryVariables = Exact<{
  project?: Maybe<Scalars['Int']>;
}>;


export type TasksQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<Array<Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )> }
  )>>> }
);

export type TaskQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TaskQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'state' | 'description'>
  )> }
);

export type CurrentPlanningQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentPlanningQuery = (
  { __typename?: 'Query' }
  & { current_planning?: Maybe<(
    { __typename?: 'Planning' }
    & PlanningAttributesFragment
  )> }
);

export type RecurrentTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type RecurrentTasksQuery = (
  { __typename?: 'Query' }
  & { recurrent_tasks?: Maybe<Array<Maybe<(
    { __typename?: 'RecurrentTask' }
    & Pick<RecurrentTask, 'id' | 'name' | 'estimate' | 'recurrence' | 'notify'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'name'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name'>
    )> }
  )>>> }
);

export type RecurrentTaskQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RecurrentTaskQuery = (
  { __typename?: 'Query' }
  & { recurrent_task?: Maybe<(
    { __typename?: 'RecurrentTask' }
    & Pick<RecurrentTask, 'id' | 'name' | 'estimate' | 'recurrence' | 'description'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateRecurrentTaskMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  estimate?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateRecurrentTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateRecurrentTask: (
    { __typename?: 'RecurrentTask' }
    & Pick<RecurrentTask, 'id' | 'name' | 'estimate' | 'description'>
  ) }
);

export type UpdatePlanningMutationVariables = Exact<{
  id: Scalars['ID'];
  state?: Maybe<PlanningState>;
  max_allocation?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  evaluation?: Maybe<Scalars['String']>;
}>;


export type UpdatePlanningMutation = (
  { __typename?: 'Mutation' }
  & { updatePlanning?: Maybe<(
    { __typename?: 'Planning' }
    & Pick<Planning, 'id' | 'week' | 'year' | 'state' | 'max_allocation'>
  )> }
);

export type AuthenticateMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type AuthenticateMutation = (
  { __typename?: 'Mutation' }
  & { authenticate: (
    { __typename: 'ValidationError' }
    & Pick<ValidationError, 'message'>
  ) | (
    { __typename: 'AuthenticatePayload' }
    & Pick<AuthenticatePayload, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    ) }
  ) }
);

export const GoalAttributesFragmentDoc = gql`
    fragment goalAttributes on Goal {
  id
  name
  description
  state
  commit_date
  completed_date
  deadline
  observations
  deliverable
  created
  updated
}
    `;
export const PlanningAttributesFragmentDoc = gql`
    fragment planningAttributes on Planning {
  id
  week
  year
  allocated
  executed
  max_allocation
  state
  goal_completion
  notes
  evaluation
}
    `;
export const Create_GoalDocument = gql`
    mutation create_goal($name: String!, $description: String, $deadline: Datetime, $commit_date: Datetime) {
  create_goal(name: $name, description: $description, deadline: $deadline, commit_date: $commit_date) {
    ...goalAttributes
  }
}
    ${GoalAttributesFragmentDoc}`;
export type Create_GoalMutationFn = ApolloReactCommon.MutationFunction<Create_GoalMutation, Create_GoalMutationVariables>;

/**
 * __useCreate_GoalMutation__
 *
 * To run a mutation, you first call `useCreate_GoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_GoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalMutation, { data, loading, error }] = useCreate_GoalMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      deadline: // value for 'deadline'
 *      commit_date: // value for 'commit_date'
 *   },
 * });
 */
export function useCreate_GoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Create_GoalMutation, Create_GoalMutationVariables>) {
        return ApolloReactHooks.useMutation<Create_GoalMutation, Create_GoalMutationVariables>(Create_GoalDocument, baseOptions);
      }
export type Create_GoalMutationHookResult = ReturnType<typeof useCreate_GoalMutation>;
export type Create_GoalMutationResult = ApolloReactCommon.MutationResult<Create_GoalMutation>;
export type Create_GoalMutationOptions = ApolloReactCommon.BaseMutationOptions<Create_GoalMutation, Create_GoalMutationVariables>;
export const CreatePlanningDocument = gql`
    mutation createPlanning($week: Int!, $year: Int!, $type: String!, $max_allocation: Int!) {
  createPlanning(week: $week, year: $year, type: $type, max_allocation: $max_allocation) {
    id
    week
    year
    type
    max_allocation
  }
}
    `;
export type CreatePlanningMutationFn = ApolloReactCommon.MutationFunction<CreatePlanningMutation, CreatePlanningMutationVariables>;

/**
 * __useCreatePlanningMutation__
 *
 * To run a mutation, you first call `useCreatePlanningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanningMutation, { data, loading, error }] = useCreatePlanningMutation({
 *   variables: {
 *      week: // value for 'week'
 *      year: // value for 'year'
 *      type: // value for 'type'
 *      max_allocation: // value for 'max_allocation'
 *   },
 * });
 */
export function useCreatePlanningMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlanningMutation, CreatePlanningMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlanningMutation, CreatePlanningMutationVariables>(CreatePlanningDocument, baseOptions);
      }
export type CreatePlanningMutationHookResult = ReturnType<typeof useCreatePlanningMutation>;
export type CreatePlanningMutationResult = ApolloReactCommon.MutationResult<CreatePlanningMutation>;
export type CreatePlanningMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlanningMutation, CreatePlanningMutationVariables>;
export const CreatePlanningTaskDocument = gql`
    mutation CreatePlanningTask($allocated: Int!, $task: Int!, $planning: Int!, $observations: String) {
  createPlanningTask(allocated: $allocated, task: $task, planning: $planning, observations: $observations) {
    id
    allocated
    executed
    task {
      id
      name
      state
      project {
        id
        name
      }
    }
    planning {
      id
    }
  }
}
    `;
export type CreatePlanningTaskMutationFn = ApolloReactCommon.MutationFunction<CreatePlanningTaskMutation, CreatePlanningTaskMutationVariables>;

/**
 * __useCreatePlanningTaskMutation__
 *
 * To run a mutation, you first call `useCreatePlanningTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanningTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanningTaskMutation, { data, loading, error }] = useCreatePlanningTaskMutation({
 *   variables: {
 *      allocated: // value for 'allocated'
 *      task: // value for 'task'
 *      planning: // value for 'planning'
 *      observations: // value for 'observations'
 *   },
 * });
 */
export function useCreatePlanningTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlanningTaskMutation, CreatePlanningTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlanningTaskMutation, CreatePlanningTaskMutationVariables>(CreatePlanningTaskDocument, baseOptions);
      }
export type CreatePlanningTaskMutationHookResult = ReturnType<typeof useCreatePlanningTaskMutation>;
export type CreatePlanningTaskMutationResult = ApolloReactCommon.MutationResult<CreatePlanningTaskMutation>;
export type CreatePlanningTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlanningTaskMutation, CreatePlanningTaskMutationVariables>;
export const UpdateGoalDocument = gql`
    mutation UpdateGoal($id: Int!, $name: String, $description: String, $deadline: Datetime, $commit_date: Datetime, $completed_date: Datetime, $observations: String, $deliverable: String, $state: GoalState) {
  updateGoal(id: $id, name: $name, description: $description, deadline: $deadline, commit_date: $commit_date, completed_date: $completed_date, observations: $observations, deliverable: $deliverable, state: $state) {
    ...goalAttributes
  }
}
    ${GoalAttributesFragmentDoc}`;
export type UpdateGoalMutationFn = ApolloReactCommon.MutationFunction<UpdateGoalMutation, UpdateGoalMutationVariables>;

/**
 * __useUpdateGoalMutation__
 *
 * To run a mutation, you first call `useUpdateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalMutation, { data, loading, error }] = useUpdateGoalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      deadline: // value for 'deadline'
 *      commit_date: // value for 'commit_date'
 *      completed_date: // value for 'completed_date'
 *      observations: // value for 'observations'
 *      deliverable: // value for 'deliverable'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGoalMutation, UpdateGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGoalMutation, UpdateGoalMutationVariables>(UpdateGoalDocument, baseOptions);
      }
export type UpdateGoalMutationHookResult = ReturnType<typeof useUpdateGoalMutation>;
export type UpdateGoalMutationResult = ApolloReactCommon.MutationResult<UpdateGoalMutation>;
export type UpdateGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGoalMutation, UpdateGoalMutationVariables>;
export const UpdatePlanningTaskDocument = gql`
    mutation UpdatePlanningTask($id: Int!, $allocated: Int, $state: String, $observations: String, $is_bookmarked: Boolean, $is_goal: Boolean, $priority: Int) {
  updatePlanningTask(id: $id, allocated: $allocated, state: $state, observations: $observations, is_bookmarked: $is_bookmarked, is_goal: $is_goal, priority: $priority) {
    id
    allocated
    state
    observations
    is_bookmarked
    is_goal
    priority
  }
}
    `;
export type UpdatePlanningTaskMutationFn = ApolloReactCommon.MutationFunction<UpdatePlanningTaskMutation, UpdatePlanningTaskMutationVariables>;

/**
 * __useUpdatePlanningTaskMutation__
 *
 * To run a mutation, you first call `useUpdatePlanningTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanningTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanningTaskMutation, { data, loading, error }] = useUpdatePlanningTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      allocated: // value for 'allocated'
 *      state: // value for 'state'
 *      observations: // value for 'observations'
 *      is_bookmarked: // value for 'is_bookmarked'
 *      is_goal: // value for 'is_goal'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useUpdatePlanningTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePlanningTaskMutation, UpdatePlanningTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePlanningTaskMutation, UpdatePlanningTaskMutationVariables>(UpdatePlanningTaskDocument, baseOptions);
      }
export type UpdatePlanningTaskMutationHookResult = ReturnType<typeof useUpdatePlanningTaskMutation>;
export type UpdatePlanningTaskMutationResult = ApolloReactCommon.MutationResult<UpdatePlanningTaskMutation>;
export type UpdatePlanningTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePlanningTaskMutation, UpdatePlanningTaskMutationVariables>;
export const DeletePlanningTaskDocument = gql`
    mutation DeletePlanningTask($id: Int!) {
  deletePlanningTask(id: $id)
}
    `;
export type DeletePlanningTaskMutationFn = ApolloReactCommon.MutationFunction<DeletePlanningTaskMutation, DeletePlanningTaskMutationVariables>;

/**
 * __useDeletePlanningTaskMutation__
 *
 * To run a mutation, you first call `useDeletePlanningTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanningTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanningTaskMutation, { data, loading, error }] = useDeletePlanningTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlanningTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePlanningTaskMutation, DeletePlanningTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePlanningTaskMutation, DeletePlanningTaskMutationVariables>(DeletePlanningTaskDocument, baseOptions);
      }
export type DeletePlanningTaskMutationHookResult = ReturnType<typeof useDeletePlanningTaskMutation>;
export type DeletePlanningTaskMutationResult = ApolloReactCommon.MutationResult<DeletePlanningTaskMutation>;
export type DeletePlanningTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlanningTaskMutation, DeletePlanningTaskMutationVariables>;
export const GetGoalsDocument = gql`
    query GetGoals($state: GoalState, $fullSearch: String) {
  get_goals(state: $state, full_search: $fullSearch) {
    count
    next
    previous
    results {
      ...goalAttributes
    }
  }
}
    ${GoalAttributesFragmentDoc}`;

/**
 * __useGetGoalsQuery__
 *
 * To run a query within a React component, call `useGetGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoalsQuery({
 *   variables: {
 *      state: // value for 'state'
 *      fullSearch: // value for 'fullSearch'
 *   },
 * });
 */
export function useGetGoalsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoalsQuery, GetGoalsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGoalsQuery, GetGoalsQueryVariables>(GetGoalsDocument, baseOptions);
      }
export function useGetGoalsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoalsQuery, GetGoalsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGoalsQuery, GetGoalsQueryVariables>(GetGoalsDocument, baseOptions);
        }
export type GetGoalsQueryHookResult = ReturnType<typeof useGetGoalsQuery>;
export type GetGoalsLazyQueryHookResult = ReturnType<typeof useGetGoalsLazyQuery>;
export type GetGoalsQueryResult = ApolloReactCommon.QueryResult<GetGoalsQuery, GetGoalsQueryVariables>;
export const GetPlanningDocument = gql`
    query getPlanning($id: ID!) {
  getPlanning(id: $id) {
    ...planningAttributes
  }
}
    ${PlanningAttributesFragmentDoc}`;

/**
 * __useGetPlanningQuery__
 *
 * To run a query within a React component, call `useGetPlanningQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanningQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlanningQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPlanningQuery, GetPlanningQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPlanningQuery, GetPlanningQueryVariables>(GetPlanningDocument, baseOptions);
      }
export function useGetPlanningLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlanningQuery, GetPlanningQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPlanningQuery, GetPlanningQueryVariables>(GetPlanningDocument, baseOptions);
        }
export type GetPlanningQueryHookResult = ReturnType<typeof useGetPlanningQuery>;
export type GetPlanningLazyQueryHookResult = ReturnType<typeof useGetPlanningLazyQuery>;
export type GetPlanningQueryResult = ApolloReactCommon.QueryResult<GetPlanningQuery, GetPlanningQueryVariables>;
export const PlanningsDocument = gql`
    query plannings {
  plannings {
    ...planningAttributes
  }
}
    ${PlanningAttributesFragmentDoc}`;

/**
 * __usePlanningsQuery__
 *
 * To run a query within a React component, call `usePlanningsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanningsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlanningsQuery, PlanningsQueryVariables>) {
        return ApolloReactHooks.useQuery<PlanningsQuery, PlanningsQueryVariables>(PlanningsDocument, baseOptions);
      }
export function usePlanningsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlanningsQuery, PlanningsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlanningsQuery, PlanningsQueryVariables>(PlanningsDocument, baseOptions);
        }
export type PlanningsQueryHookResult = ReturnType<typeof usePlanningsQuery>;
export type PlanningsLazyQueryHookResult = ReturnType<typeof usePlanningsLazyQuery>;
export type PlanningsQueryResult = ApolloReactCommon.QueryResult<PlanningsQuery, PlanningsQueryVariables>;
export const PlanningTasksDocument = gql`
    query PlanningTasks($planning: Int, $state_In: String) {
  planning_tasks(planning: $planning, state_In: $state_In) {
    id
    allocated
    executed
    state
    observations
    is_bookmarked
    is_goal
    priority
    task {
      id
      name
      state
      project {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePlanningTasksQuery__
 *
 * To run a query within a React component, call `usePlanningTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningTasksQuery({
 *   variables: {
 *      planning: // value for 'planning'
 *      state_In: // value for 'state_In'
 *   },
 * });
 */
export function usePlanningTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlanningTasksQuery, PlanningTasksQueryVariables>) {
        return ApolloReactHooks.useQuery<PlanningTasksQuery, PlanningTasksQueryVariables>(PlanningTasksDocument, baseOptions);
      }
export function usePlanningTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlanningTasksQuery, PlanningTasksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlanningTasksQuery, PlanningTasksQueryVariables>(PlanningTasksDocument, baseOptions);
        }
export type PlanningTasksQueryHookResult = ReturnType<typeof usePlanningTasksQuery>;
export type PlanningTasksLazyQueryHookResult = ReturnType<typeof usePlanningTasksLazyQuery>;
export type PlanningTasksQueryResult = ApolloReactCommon.QueryResult<PlanningTasksQuery, PlanningTasksQueryVariables>;
export const ProjectsDocument = gql`
    query projects {
  projects {
    id
    name
    state
  }
}
    `;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
      }
export function useProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = ApolloReactCommon.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const ActiveProjectsDocument = gql`
    query activeProjects {
  active_projects {
    id
    name
    state
  }
}
    `;

/**
 * __useActiveProjectsQuery__
 *
 * To run a query within a React component, call `useActiveProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActiveProjectsQuery, ActiveProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ActiveProjectsQuery, ActiveProjectsQueryVariables>(ActiveProjectsDocument, baseOptions);
      }
export function useActiveProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActiveProjectsQuery, ActiveProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActiveProjectsQuery, ActiveProjectsQueryVariables>(ActiveProjectsDocument, baseOptions);
        }
export type ActiveProjectsQueryHookResult = ReturnType<typeof useActiveProjectsQuery>;
export type ActiveProjectsLazyQueryHookResult = ReturnType<typeof useActiveProjectsLazyQuery>;
export type ActiveProjectsQueryResult = ApolloReactCommon.QueryResult<ActiveProjectsQuery, ActiveProjectsQueryVariables>;
export const TasksDocument = gql`
    query Tasks($project: Int) {
  tasks(project: $project, state_In: "CREATED,STOPPED,EXECUTING,ESTIMATED", user: 1) {
    id
    name
    project {
      id
      name
    }
  }
}
    `;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        return ApolloReactHooks.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, baseOptions);
      }
export function useTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, baseOptions);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = ApolloReactCommon.QueryResult<TasksQuery, TasksQueryVariables>;
export const TaskDocument = gql`
    query Task($id: Int!) {
  task(id: $id) {
    id
    name
    state
    description
  }
}
    `;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        return ApolloReactHooks.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, baseOptions);
      }
export function useTaskLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, baseOptions);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = ApolloReactCommon.QueryResult<TaskQuery, TaskQueryVariables>;
export const CurrentPlanningDocument = gql`
    query currentPlanning {
  current_planning {
    ...planningAttributes
  }
}
    ${PlanningAttributesFragmentDoc}`;

/**
 * __useCurrentPlanningQuery__
 *
 * To run a query within a React component, call `useCurrentPlanningQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPlanningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentPlanningQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentPlanningQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentPlanningQuery, CurrentPlanningQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentPlanningQuery, CurrentPlanningQueryVariables>(CurrentPlanningDocument, baseOptions);
      }
export function useCurrentPlanningLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentPlanningQuery, CurrentPlanningQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentPlanningQuery, CurrentPlanningQueryVariables>(CurrentPlanningDocument, baseOptions);
        }
export type CurrentPlanningQueryHookResult = ReturnType<typeof useCurrentPlanningQuery>;
export type CurrentPlanningLazyQueryHookResult = ReturnType<typeof useCurrentPlanningLazyQuery>;
export type CurrentPlanningQueryResult = ApolloReactCommon.QueryResult<CurrentPlanningQuery, CurrentPlanningQueryVariables>;
export const RecurrentTasksDocument = gql`
    query RecurrentTasks {
  recurrent_tasks {
    id
    name
    estimate
    recurrence
    notify
    project {
      name
    }
    user {
      name
    }
  }
}
    `;

/**
 * __useRecurrentTasksQuery__
 *
 * To run a query within a React component, call `useRecurrentTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecurrentTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurrentTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecurrentTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecurrentTasksQuery, RecurrentTasksQueryVariables>) {
        return ApolloReactHooks.useQuery<RecurrentTasksQuery, RecurrentTasksQueryVariables>(RecurrentTasksDocument, baseOptions);
      }
export function useRecurrentTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecurrentTasksQuery, RecurrentTasksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecurrentTasksQuery, RecurrentTasksQueryVariables>(RecurrentTasksDocument, baseOptions);
        }
export type RecurrentTasksQueryHookResult = ReturnType<typeof useRecurrentTasksQuery>;
export type RecurrentTasksLazyQueryHookResult = ReturnType<typeof useRecurrentTasksLazyQuery>;
export type RecurrentTasksQueryResult = ApolloReactCommon.QueryResult<RecurrentTasksQuery, RecurrentTasksQueryVariables>;
export const RecurrentTaskDocument = gql`
    query RecurrentTask($id: Int!) {
  recurrent_task(id: $id) {
    id
    name
    estimate
    recurrence
    description
    project {
      id
      name
    }
  }
}
    `;

/**
 * __useRecurrentTaskQuery__
 *
 * To run a query within a React component, call `useRecurrentTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecurrentTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurrentTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecurrentTaskQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecurrentTaskQuery, RecurrentTaskQueryVariables>) {
        return ApolloReactHooks.useQuery<RecurrentTaskQuery, RecurrentTaskQueryVariables>(RecurrentTaskDocument, baseOptions);
      }
export function useRecurrentTaskLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecurrentTaskQuery, RecurrentTaskQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecurrentTaskQuery, RecurrentTaskQueryVariables>(RecurrentTaskDocument, baseOptions);
        }
export type RecurrentTaskQueryHookResult = ReturnType<typeof useRecurrentTaskQuery>;
export type RecurrentTaskLazyQueryHookResult = ReturnType<typeof useRecurrentTaskLazyQuery>;
export type RecurrentTaskQueryResult = ApolloReactCommon.QueryResult<RecurrentTaskQuery, RecurrentTaskQueryVariables>;
export const UpdateRecurrentTaskDocument = gql`
    mutation UpdateRecurrentTask($id: Int!, $name: String, $estimate: Int, $description: String) {
  updateRecurrentTask(id: $id, name: $name, estimate: $estimate, description: $description) {
    id
    name
    estimate
    description
  }
}
    `;
export type UpdateRecurrentTaskMutationFn = ApolloReactCommon.MutationFunction<UpdateRecurrentTaskMutation, UpdateRecurrentTaskMutationVariables>;

/**
 * __useUpdateRecurrentTaskMutation__
 *
 * To run a mutation, you first call `useUpdateRecurrentTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurrentTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurrentTaskMutation, { data, loading, error }] = useUpdateRecurrentTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      estimate: // value for 'estimate'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateRecurrentTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRecurrentTaskMutation, UpdateRecurrentTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRecurrentTaskMutation, UpdateRecurrentTaskMutationVariables>(UpdateRecurrentTaskDocument, baseOptions);
      }
export type UpdateRecurrentTaskMutationHookResult = ReturnType<typeof useUpdateRecurrentTaskMutation>;
export type UpdateRecurrentTaskMutationResult = ApolloReactCommon.MutationResult<UpdateRecurrentTaskMutation>;
export type UpdateRecurrentTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRecurrentTaskMutation, UpdateRecurrentTaskMutationVariables>;
export const UpdatePlanningDocument = gql`
    mutation updatePlanning($id: ID!, $state: PlanningState, $max_allocation: Int, $notes: String, $evaluation: String) {
  updatePlanning(id: $id, state: $state, max_allocation: $max_allocation, notes: $notes, evaluation: $evaluation) {
    id
    week
    year
    state
    max_allocation
  }
}
    `;
export type UpdatePlanningMutationFn = ApolloReactCommon.MutationFunction<UpdatePlanningMutation, UpdatePlanningMutationVariables>;

/**
 * __useUpdatePlanningMutation__
 *
 * To run a mutation, you first call `useUpdatePlanningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanningMutation, { data, loading, error }] = useUpdatePlanningMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *      max_allocation: // value for 'max_allocation'
 *      notes: // value for 'notes'
 *      evaluation: // value for 'evaluation'
 *   },
 * });
 */
export function useUpdatePlanningMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePlanningMutation, UpdatePlanningMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePlanningMutation, UpdatePlanningMutationVariables>(UpdatePlanningDocument, baseOptions);
      }
export type UpdatePlanningMutationHookResult = ReturnType<typeof useUpdatePlanningMutation>;
export type UpdatePlanningMutationResult = ApolloReactCommon.MutationResult<UpdatePlanningMutation>;
export type UpdatePlanningMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePlanningMutation, UpdatePlanningMutationVariables>;
export const AuthenticateDocument = gql`
    mutation authenticate($email: String, $password: String) {
  authenticate(email: $email, password: $password) {
    __typename
    ... on ValidationError {
      message
    }
    ... on AuthenticatePayload {
      token
      user {
        id
        name
      }
    }
  }
}
    `;
export type AuthenticateMutationFn = ApolloReactCommon.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, baseOptions);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = ApolloReactCommon.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;