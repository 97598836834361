import 'moment/locale/pt';
import React from 'react';
import Moment from 'react-moment';

const FormatDate = ({ date, format }: { date: any, format?: any }) => {
    if (!date) {
        return null;
    }
    return (
        <Moment format={format ? format : "DD-MM-YYYY"}>
            {date}
        </Moment>);
};

export default FormatDate;
