import classNames from 'classnames';
import React from 'react';
import styles from './MultiStateToggle.module.scss';

/** Toggle button that supports more than 2 states, similiar to a radio group */
const MultiStateToggle = ({ name, value, options, onChange }: { name: string, value: string, options: [{ id: string, value: string }], onChange: any }) => {

    const handleClick = (event: any, id: string) => {
        event.target.name = name;
        event.target.value = id
        if (event.target.value !== value) {
            onChange(event)
        }
    }

    return (
        <div className={styles.MultiStateWrapper}>
            {options.map((option: any) => (
                <span key={option.id} className={classNames([styles.Option, { [styles.Active]: option.id === value }])} onClick={event => handleClick(event, option.id)}>
                    {option.label}
                </span>
            ))}
        </div>
    )

}


export default MultiStateToggle;
