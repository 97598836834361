import { useMutation, useQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import React from 'react';
import { Link } from 'react-router-dom';
import { IS_MENU_OPEN, SET_MENU } from '../../services/Local';
import styles from './Sidemenu.module.scss';

const EnabledMenuItem = (item: any) => (
    <Link to={item.route} onClick={item.handler}>
        {item.label}
    </Link>
);

const SidemenuItem = (item: any) => {
    return <EnabledMenuItem {...item} />;
};

const Sidemenu = () => {

    const { data } = useQuery(IS_MENU_OPEN);
    const [setMenu] = useMutation(SET_MENU);

    const closeMenu = () => {
        setMenu({ variables: { state: false } });
    };

    const onRouteChange = () => {
        closeMenu();
    };

    const menuItems = [
        {
            route: '/',
            label: "Dashboard",
            handler: onRouteChange,
        },
        {
            route: '/goals',
            label: "Goals",
            handler: onRouteChange,
        },
        {
            route: '/plannings',
            label: "Plannings",
            handler: onRouteChange,
        },
        {
            route: '/recurrent-tasks',
            label: "Recurrent Tasks",
            handler: onRouteChange,
        },
        {
            route: '/logout',
            label: "Logout",
            handler: onRouteChange,
        },
    ];

    return (
        <Drawer open={data.isMenuOpen} onClose={() => false}>
            <Grid className={styles.Sidemenu}>

                <Grid container direction="column" className={styles.Buttons}>
                    {menuItems.map(item => <SidemenuItem key={item.route} {...item} />)}
                </Grid>

            </Grid>
        </Drawer>
    );
};

export default Sidemenu;
