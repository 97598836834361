import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { useAuthenticateMutation } from '../../generated/graphql';
import { useChangeHandler, useUserRedirection } from '../../services/Hooks';
import { LOGIN_USER } from '../../services/Local';
import GenericTextField from '../TextField/TextField';
import styles from './Login.module.scss';

const Login = () => {

    const [user, setUser] = useState({});
    const [errors, setErrors] = useState();
    const [validationError, setValidationError]: any = useState({ message: null });
    const [authenticate] = useAuthenticateMutation()
    const changeHandler = useChangeHandler(user, setUser);
    const [loginUser] = useMutation(LOGIN_USER);

    useUserRedirection();

    const fields = [
        {
            key: 1,
            name: "email",
            label: "Email",
            changeHandler: changeHandler,
            required: true,
            errors
        },
        {
            key: 2,
            name: "password",
            label: "Password",
            type: "password",
            changeHandler: changeHandler,
            required: true,
            errors
        },
    ];

    const handleLoginUser = (token: any) => {
        loginUser({ variables: { token } }).then(() => setErrors({}));
    };


    const handleSubmit = (event: any) => {
        event.preventDefault();
        // @ts-ignore
        authenticate({ variables: { email: user.email, password: user.password } }).then(
            response => {
                if (response?.data?.authenticate.__typename === 'ValidationError') {
                    if (response.data.authenticate.message) {
                        setValidationError({ message: response.data.authenticate.message });
                    } else {
                        setValidationError({ message: 'Unknown Error' });
                    }
                } else {
                    handleLoginUser(response.data);
                }
            }
        );
    };

    return (
        <Grid container direction="row" justify="center">
            <Card className={styles.Login}>
                <CardContent>

                    <h1>Login</h1>

                    <Grid container direction="column">

                        <form onSubmit={handleSubmit}>
                            {fields.map(
                                field => <GenericTextField key={field.key} {...field} />
                            )}

                            <div className={styles.ErrorMessage}>
                                {validationError.message}
                            </div>

                            <Button
                                data-cy="submit"
                                color='primary'
                                variant='contained'
                                type="submit"
                                className={styles.Submit}
                            >
                                Login now
                  </Button>
                        </form>

                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Login;
