import React from 'react';
import { useTaskQuery } from '../../generated/graphql';
import { useGuestRedirection } from '../../services/Hooks';

interface TaskProps {
    match: { params: { id: string } }
}

const Task: React.FC<TaskProps> = props => {

    useGuestRedirection();

    const id = parseInt(props.match.params.id);
    const { loading, error, data } = useTaskQuery({ variables: { id } })

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error</p>;
    }

    if (!data?.task) {
        return <div>Could not find task.</div>
    }

    return (
        <>
            <p>Name: {data.task.name}</p>
            <p>State: {data.task.state}</p>
            <p>Description: {data.task.description}</p>
        </>
    );
};

export default Task;
