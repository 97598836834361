import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { FabContext } from '../services/FabProvider';

export const useChangeHandler = (obj: any, setObj: any, config: any = null) => {
    const func = (event: any) => {
        const field = event.target.name;
        const value = event.target.value;
        if (config && config.hasOwnProperty(field)) {
            const listValue = [value]
            setObj({ ...obj, [field]: listValue });
        } else {
            setObj({ ...obj, [field]: value });
        }
    }
    return func;
};

export const useGuestRedirection = () => {
    const history = useHistory();
    const location = useLocation();
    const auth_token = localStorage.getItem('auth.token')
    if (!auth_token) {
        history.push('/login', { redirectTo: location.pathname })
    }
};

export const useUserRedirection = () => {
    const history = useHistory();
    const auth_token = localStorage.getItem('auth.token');

    if (auth_token) {
        let url = '/';
        try {
            // @ts-ignore
            url = history.location.state.redirectTo;
        }
        catch (error) {
            console.error(error);
        }
        history.push(url);
    }
};

/* eslint-disable react-hooks/exhaustive-deps */
export const useGlobalFab = (callback: any) => {
    const { setAction } = useContext(FabContext);
    useEffect(() => {
        setAction(() => callback);
        return () => setAction(() => null);
    }, [setAction]);
}
