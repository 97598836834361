import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent, useState } from 'react';
import { Planning, useCreatePlanningMutation, useUpdatePlanningMutation } from '../../generated/graphql';
import { GET_PLANNINGS } from '../../services/Queries';
import styles from './CreatePlanning.module.scss';

interface CreatePlanningProps {
    onComplete: () => void,
    id?: number,
}

interface PlanningStub {
    type: string
    max_allocation?: number,
    evaluation?: string
}

const CreatePlanning: React.FC<CreatePlanningProps> = ({ id, onComplete }) => {

    const [createPlanning] = useCreatePlanningMutation()
    const [updatePlanning] = useUpdatePlanningMutation()
    const [planning, setPlanning] = useState<Planning | PlanningStub>({ type: 'WEEKLY' });
    const submitText = id ? 'Update Planning' : 'Create Planning';

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const field = event.target.name;
        let value: string | number = event.target.value;
        if (['week', 'year', 'max_allocation'].includes(field)) {
            value = parseInt(value);
        }
        setPlanning((prevState: any) => {
            return { ...prevState, [field]: value }
        });
    };

    const handleSubmit = () => {
        if (id) {
            // @ts-ignore
            updatePlanning({ variables: { id, max_allocation: planning.max_allocation, evaluation: planning.evaluation } }).then(onComplete);
        } else {
            // @ts-ignore
            createPlanning({
                variables: { ...planning },
                refetchQueries: [{
                    query: GET_PLANNINGS
                }]
            }).then(onComplete);
        }
    };

    return (
        <div className={styles.CreatePlanning}>
            <Grid container direction="column">
                <p>ID: {id}</p>
                {!id && <TextField type="number" name="week" placeholder="week" onChange={changeHandler} autoFocus />}
                {!id && <TextField type="number" name="year" placeholder="year" onChange={changeHandler} />}
                <TextField type="number" name="max_allocation" placeholder="max_allocation" onChange={changeHandler} />
                <TextField name="evaluation" placeholder="evaluation" multiline onChange={changeHandler} />
                <Button onClick={handleSubmit}>{submitText}</Button>
            </Grid>
        </div>
    );
};

export default CreatePlanning;
