import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import FormatDate from '../FormatDate/FormatDate';
import styles from './GoalDetails.module.scss';

const GoalDetails = ({ goal, onComplete, onEdit }: { goal: any, onComplete: () => any, onEdit: () => any }) => {

    return (
        <Grid container direction="column" alignItems="flex-start" className={styles.Wrapper}>

            <Grid container justify="space-between" alignItems="center" className={styles.Actions}>
                <div>
                    <span className={styles.State}>{goal.state}</span>
                </div>
                <Box>
                    <IconButton onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => alert('not implemented yet')}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={onComplete}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Grid>

            <p className={styles.Name}>
                {'#' + goal.id} - {goal.name || 'Sem Nome'}
            </p>
            <p className={styles.Process}>

            </p>

            <div>
                <p className={styles.SectionHeader}>
                    Descrição
		</p>

                <p className={styles.SectionContent}>
                    {goal.description}
                </p>
            </div>

            {goal.deliverable ? <div>
                <p className={styles.SectionHeader}>
                    Deliverable
		</p>
                <p className={styles.SectionContent}>
                    {goal.deliverable}
                </p>
            </div> : ''}


            {goal.observations ? <div>
                <p className={styles.SectionHeader}>
                    Observations
		</p>
                <p className={styles.SectionContent}>
                    {goal.observations}
                </p>
            </div> : ''}

            <div>
                <p className={styles.SectionHeader}>
                    Datas
	    </p>

                <TableContainer>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Commited Date
                            </TableCell>
                                <TableCell>
                                    {goal.commit_date ? <FormatDate date={goal.commit_date} /> : '- - -'}
                                </TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Deadline Date
                            </TableCell>
                                <TableCell>
                                    {goal.deadline ? <FormatDate date={goal.deadline} /> : '- - -'}
                                </TableCell>

                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    Completed Date
                            </TableCell>
                                <TableCell>
                                    {goal.completed_date ? <FormatDate date={goal.completed_date} /> : '- - -'}
                                </TableCell>

                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>

            </div>

        </Grid>
    )
};

export default GoalDetails;
