import { useQuery } from '@apollo/react-hooks';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { ACTIVE_PROJECTS } from '../../services/Queries';
import styles from './ProjectSelector.module.scss';

const ProjectSelector = (props: any) => {

    const { loading, error, data } = useQuery(ACTIVE_PROJECTS);

    if (!props.visible) {
        return null;
    }

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error...</p>;
    }

    const handleChange = (event: any) => {
        props.set(event.target.value);
    };

    return (
        <FormControl className={styles.ProjectSelector}>
            <InputLabel>Project</InputLabel>
            <Select value={props.selected} onChange={handleChange}>
                {data.active_projects.map(
                    (project: any) => <MenuItem value={project.id} key={project.id}>{project.name}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
};

export default ProjectSelector;
