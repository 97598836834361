import { Config } from "./Config";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import gql from 'graphql-tag';
import { setContext } from 'apollo-link-context';

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('auth.token');
    const prefix = Config.JWT_AUTH_HEADER_PREFIX;
    return {
        headers: {
            ...headers,
            'authorization': token ? `${prefix} ${token}` : "",
        }
    };
});

const uploadLink = createUploadLink({
    uri: Config.GRAPHQL_API
});


export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(uploadLink),
    resolvers: {
        Mutation: {
            loginUser: (_root, variables, { cache, getCacheKey }) => {
                const token = variables.token.authenticate.token;
                cache.writeData({ data: { token } });
                localStorage.setItem('auth.token', token);
                return true
            },
            logoutUser: (_root, variables, { cache, getCacheKey }) => {
                localStorage.removeItem('auth.token');
                cache.writeData({ data: { token: null } });
                return null;
            },
            setMenu: (_root, variables, { cache, getCacheKey }) => {
                const state = variables.state;;
                cache.writeData({ data: { isMenuOpen: state } });
            }
        },
    }
});

const query = gql`
  query {
      token
      isMenuOpen
  }
`;

client.writeQuery({ query, data: { token: localStorage.getItem('auth.token'), isMenuOpen: false } })

export default client;
