import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import Moment from 'react-moment';
import { useGetGoalsQuery } from '../../generated/graphql';
import { useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import AutoTextfield from '../AutoTextField/AutoTextField';
import CreateGoal from '../CreateGoal/CreateGoal';
import CustomModal from '../CustomModal/CustomModal';
import EditGoal from '../EditGoal/EditGoal';
import FormatDate from '../FormatDate/FormatDate';
import GoalDetails from '../GoalDetails/GoalDetails';
import Loading from '../Loading/Loading';
import styles from './GoalsView.module.scss';

const GoalsFilters = ({ filters, setFilters }: { filters: { fullSearch: string, state: string }, setFilters: any }) => {

    let handleChange = (event: any) => {
        try {
            const value = event.target.value;
            const name = event.target.name;
            setFilters({ ...filters, [name]: value })
        } catch (e) {
            setFilters({ ...filters, fullSearch: event['fullSearch'] })
        }
    }

    return (
        <Grid container alignItems="flex-end" className={styles.GoalsFilters}>
            <AutoTextfield
                placeholder="search"
                value={filters.fullSearch}
                name="fullSearch"
                onSave={handleChange}
                interval={500}
                autoFocus
                autoComplete="off"
            />
            <FormControl className={styles.ProjectSelector}>
                <InputLabel>State</InputLabel>
                <Select value={filters.state} onChange={handleChange} name="state">
                    {['CREATED', 'COMMITTED', 'COMPLETED'].map(
                        (state: string) => <MenuItem value={state} key={state}>{state}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </Grid>
    )
}

const GoalsView = () => {

    useGuestRedirection();

    const [filters, setFilters] = useState({ fullSearch: '', state: "COMMITTED" })
    // @ts-ignore
    const { data, loading, refetch } = useGetGoalsQuery({ variables: { ...filters } })
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [viewIssue, setViewIssue] = useState();


    useGlobalFab(() => setCreateModalOpen(true));

    if (loading) {
        return <Loading />;
    }

    if (!data?.get_goals) {
        return <div>Error</div>
    }

    if (!data.get_goals.results) {
        return <div>Empty State</div>
    }

    const onComplete = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setCreateModalOpen(false);
    };

    const showDelta = (goal: any) => {
        return goal.deadline && goal.state === 'COMMITTED' && !goal.completed_date;
    };

    const goals = data.get_goals.results;

    // @ts-ignore
    const today = new Moment()

    const handle = (goal: any) => {
        setViewIssue(goal);
        setViewModalOpen(true);
    }

    const handleEditIssue = () => {
        setViewModalOpen(false);
        setEditModalOpen(true);
    }

    return (
        <>
            <GoalsFilters filters={filters} setFilters={setFilters} />
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Description</TableCell>
                            <TableCell align="right">Deadline</TableCell>
                            <TableCell align="right">Commit Date</TableCell>
                            <TableCell align="right">Completed Date</TableCell>
                            <TableCell align="right">Delta</TableCell>
                            <TableCell align="right">State</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {goals.map((goal: any) => (
                            <TableRow key={goal.id}>
                                <TableCell component="th" scope="goal">
                                    <span onClick={() => handle(goal)} className={styles.GoalName}>{goal.name}</span>
                                </TableCell>
                                <TableCell align="right" className={styles.Description}>
                                    {goal.description}
                                </TableCell>
                                <TableCell align="right">
                                    <FormatDate date={goal.deadline} />
                                </TableCell>
                                <TableCell align="right">
                                    <FormatDate date={goal.commit_date} />
                                </TableCell>
                                <TableCell align="right">
                                    <FormatDate date={goal.completed_date} />
                                </TableCell>
                                <TableCell align="right">
                                    {goal.completed_data &&
                                        <FormatDate date={goal.completed_date} />}
                                    {showDelta(goal) && <Moment diff={today} unit="days">
                                        {goal.deadline}
                                    </Moment>}
                                </TableCell>
                                <TableCell align="right">
                                    {goal.state}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomModal isActive={isCreateModalOpen} title="Create Goal" handleClose={() => setCreateModalOpen(false)}>
                <CreateGoal onComplete={onComplete} />
            </CustomModal>
            <CustomModal
                isActive={isViewModalOpen}
                title=""
                showClose={false}
                handleClose={() => setViewModalOpen(false)}>
                <GoalDetails
                    goal={viewIssue}
                    onComplete={() => setViewModalOpen(false)}
                    onEdit={handleEditIssue} />
            </CustomModal>
            <CustomModal
                isActive={isEditModalOpen}
                title="Edit Issue"
                handleClose={() => setEditModalOpen(false)}>
                <EditGoal
                    goal={viewIssue}
                    onComplete={() => {
                        refetch();
                        setViewModalOpen(false);
                        setEditModalOpen(false);
                    }}
                />

            </CustomModal>

        </>
    );
};

export default GoalsView;
