import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const PlanningSelector = (props: any) => {

    if (props.hide) {
        return null;
    }

    return (
        <FormControl fullWidth>
            <TextField
                margin="dense"
                id="planning"
                name="planning"
                label="Planning"
                defaultValue={props.initial}
                onChange={props.set}
                type="number"
                required
            />
        </FormControl>
    );
};

export default PlanningSelector;
