import { useQuery } from '@apollo/react-hooks';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { TASKS } from '../../services/Queries';
import styles from './TaskSelector.module.scss';


const TaskSelector = (props: any) => {

    const { loading, error, data } = useQuery(TASKS, {
        variables: { project: props.project }
    });

    if (!props.project || !props.visible) {
        return null;
    }

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error</p>;
    }

    const handleChange = (event: any) => {
        props.set(event.target.value);
    };

    return (
        <FormControl className={styles.TaskSelector}>
            <InputLabel>Task</InputLabel>
            <Select value={props.selected} onChange={handleChange} >
                {data.tasks.map((task: any) => <MenuItem value={task.id} key={task.id}>{task.name}</MenuItem>)}
            </Select>
        </FormControl>
    );
};

export default TaskSelector;
