import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './PlanningPreview.module.scss';

const PlanningPreview = ({ planning, update }: { planning: any, update: any }) => {
    const to = `/planning/${planning.id}`;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Card className={styles.PlanningPreview}>
            <CardHeader title={'Week#' + planning.week + ' ' + planning.year + ' (' + planning.state + ')'} action={
                <>
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => update(planning, 'DRAFT')}>Draft</MenuItem>
                        <MenuItem onClick={() => update(planning, 'APPROVED')}>Approved</MenuItem>
                        <MenuItem onClick={() => update(planning, 'FINISHED')}>Finished</MenuItem>
                        <MenuItem onClick={handleClose} disabled>Delete</MenuItem>
                    </Menu>
                </>
            } />
            <CardContent>
                <p>MAX: {planning.max_allocation}</p>
                <p>ALLOCATED: {planning.allocated}</p>
                <p>EXECUTED: {planning.executed}</p>
                <p>EVALUATION: {planning.evaluation}</p>
                <Link to={to}>
                    <p style={{ color: "#4a4a4a" }}>
                        <span>Open Planning</span>
                    </p>
                </Link>
            </CardContent>
        </Card>

    );
};

export default PlanningPreview;
