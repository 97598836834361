import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecurrentTasksQuery } from '../../generated/graphql';
import { useGuestRedirection } from '../../services/Hooks';
import styles from './RecurrentTasks.module.scss';

const RecurrentTasks = () => {

    useGuestRedirection();

    const { loading, error, data } = useRecurrentTasksQuery()

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error || !data?.recurrent_tasks) {
        return <p>Error</p>;
    }

    if (!data.recurrent_tasks) {
        return <div>Empty State</div>;
    }

    const tasks = data.recurrent_tasks;

    return (
        <TableContainer component={Paper} className={styles.RecurrenceTable}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Project</TableCell>
                        <TableCell align="right">Recurrence</TableCell>
                        <TableCell align="right">User</TableCell>
                        <TableCell align="right">Notify</TableCell>
                        <TableCell align="right">Estimate (in seconds)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tasks.map((task: any) => (
                        <TableRow key={task.id}>
                            <TableCell component="th" scope="goal">
                                <Link to={'/recurrent-tasks/' + task.id}>
                                    {task.name}
                                </Link>
                            </TableCell>
                            <TableCell align="right">
                                {task.project.name}
                            </TableCell>
                            <TableCell align="right">
                                {task.recurrence}
                            </TableCell>
                            <TableCell align="right">
                                {task.user.name}
                            </TableCell>
                            <TableCell align="right">
                                {task.notify}
                            </TableCell>
                            <TableCell align="right">
                                {task.estimate}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

};

export default RecurrentTasks;
