import { ApolloProvider } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Dashboard from "./components/Dashboard/Dashboard";
import GlobalFab from './components/GlobalFab/GlobalFab';
import GoalsView from './components/GoalsView/GoalsView';
import Header from './components/Header/Header';
import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import PlanningDetails from "./components/PlanningDetails/PlanningDetails";
import PlanningsView from "./components/PlanningsView/PlanningsView";
import RecurrentTask from "./components/RecurrentTask/RecurrentTask";
import RecurrentTasks from "./components/RecurrentTasks/RecurrentTasks";
import Sidemenu from "./components/Sidemenu/Sidemenu";
import Task from "./components/Task/Task";
import client from "./services/Client";
import { FabProvider } from './services/FabProvider';

const App: React.FC = () => {

    const [action, setAction] = useState();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ApolloProvider client={client}>
                <Router>
                    <Header />
                    <Sidemenu />
                    <FabProvider value={{ action, setAction }}>
                        <Route exact path="/" component={Dashboard} />
                        <Route exact path="/goals" component={GoalsView} />
                        <Route exact path="/plannings" component={PlanningsView} />
                        <Route exact path="/planning/:id" component={PlanningDetails} />
                        <Route exact path="/recurrent-tasks" component={RecurrentTasks} />
                        <Route exact path="/recurrent-tasks/:id" component={RecurrentTask} />
                        <Route exact path="/task/:id" component={Task} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/logout" component={Logout} />
                        <GlobalFab />
                    </FabProvider>
                </Router>
            </ApolloProvider>
        </MuiPickersUtilsProvider>
    );
};

export default App;
