import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { CREATE_PLANNING_TASK, UPDATE_PLANNING_TASK } from '../../services/Queries';
import DurationSelector from '../DurationSelector/DurationSelector';
import ObservationsInput from '../ObservationsInput/ObservationsInput';
import PlanningSelector from '../PlanningSelector/PlanningSelector';
import ProjectSelector from '../ProjectSelector/ProjectSelector';
import TaskSelector from '../TaskSelector/TaskSelector';

const ModalForm = (props: any) => {

    const [project, setProject] = useState('');
    const [task, setTask] = useState('');
    const [createPlanningTask] = useMutation(CREATE_PLANNING_TASK, { onCompleted: props.close });
    const [updatePlanningTask] = useMutation(UPDATE_PLANNING_TASK);

    var creating = false;
    var actionName = 'Create';

    if (!props.visible) {
        return null;
    }

    let title = '';

    let data = {
        task: '',
        allocated: 0,
        planning: 1,
        project: '',
        observations: ''
    };

    if (props.pt) {
        title = 'Update Task';
        data.task = props.pt.task.id;
        data.allocated = props.pt.allocated;
        data.observations = props.pt.observations;
        // @ts-ignore
        data.is_goal = props.pt.is_goal;
        // @ts-ignore
        data.priority = props.pt.priority;
        actionName = 'Update';
    } else {
        title = 'Create Task';
        creating = true;
        actionName = 'Create';
        data.planning = props.planning.id;
    }

    const createOrUpdate = () => {
        if (creating) {
            create();
        } else {
            update();
        }
    };

    const create = () => {
        // @ts-ignore
        const planning = parseInt(data.planning);
        // @ts-ignore
        const allocated = parseInt(data.allocated);
        createPlanningTask({ variables: { allocated, task, planning, observations: data.observations } });
    };

    const update = () => {
        // @ts-ignore
        const allocated = parseInt(data.allocated);
        // @ts-ignore
        const priority = parseInt(data.priority);
        updatePlanningTask({ variables: { id: props.pt.id, allocated, priority, observations: data.observations } });
    };

    const handleInputChange = (event: any) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        // @ts-ignore
        data[name] = value;
    };

    const handleProjectChange = (value: any) => {
        setProject(value);
    };

    const handleTaskChange = (value: any) => {
        setTask(value);
    };

    return (
        <Dialog open={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <form>
                    <ProjectSelector selected={project} set={handleProjectChange} visible={creating} />
                    <TaskSelector project={project} selected={task} set={handleTaskChange} visible={creating} />
                    <PlanningSelector initial={data.planning} set={handleInputChange} hide={!creating} />
                    <DurationSelector initial={data.allocated} set={handleInputChange} />
                    // @ts-ignore
                    {data.is_goal && <TextField name="priority" label="priority" fullWidth onChange={handleInputChange} defaultValue={data.priority} />}
                    // @ts-ignore
                    {data.is_goal && <small>Bigger numbers represent smaller priorities</small>}
                    <ObservationsInput initial={data.observations} set={handleInputChange} />
                </form>


            </DialogContent>
            <DialogActions>
                <Button onClick={createOrUpdate} color="primary">
                    {actionName}
                </Button>
                <Button onClick={props.close} color="primary">
                    Close
		</Button>
            </DialogActions>

        </Dialog>
    );
};

export default ModalForm;
