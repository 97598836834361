import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import React from 'react';
import { useUpdateGoalMutation } from '../../generated/graphql';
import { errorParser } from '../../services/Utils';
import MultiStateToggle from '../MultiStateToggle/MultiStateToggle';
import styles from './EditGoal.module.scss';



const EditGoal = ({ goal, onComplete }: { goal: any, onComplete: any }) => {

    const [updateGoal] = useUpdateGoalMutation()

    const stateOptions = [
        { id: "CREATED", label: "Created" },
        { id: "COMMITTED", label: "Committed" },
        { id: "COMPLETED", label: "Completed" },
    ]

    const submit = (values: any) => {
        // @ts-ignore
        return updateGoal({ variables: { ...values, id: parseInt(values.id) } });
    };

    return (
        <Grid container direction="column" alignItems="flex-start" className={styles.Wrapper}>

            <p className={styles.Name}>
                Editar
            </p>


            <Formik
                initialValues={{
                    ...goal
                }}
                onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                    submit(values).then((response: any) => {
                        setSubmitting(false);
                        if (response.data.updateGoal.errors) {
                            const e = errorParser(response);
                            setErrors(e);
                        } else {
                            resetForm();
                            onComplete();
                        }
                    });
                }}
            >
                {({ isValid, submitForm, isSubmitting }) => (
                    <Form style={{ width: '100%' }}>

                        <p className={styles.SectionHeader}>
                            Name
			</p>

                        <Field
                            component={TextField}
                            variant="outlined"
                            name="name"
                            type="text"
                            fullWidth
                            data-cy="name"
                        />

                        <p className={styles.SectionHeader}>
                            Description
			</p>

                        <Field
                            component={TextField}
                            variant="outlined"
                            name="description"
                            type="text"
                            fullWidth
                            multiline
                            rows="3"
                            data-cy="description"
                        />

                        <p className={styles.SectionHeader}>
                            Deliverable
			</p>

                        <Field
                            component={TextField}
                            variant="outlined"
                            name="deliverable"
                            type="text"
                            fullWidth
                            multiline
                            rows="3"
                            data-cy="deliverable"
                        />

                        <p className={styles.SectionHeader}>
                            Observations
			</p>

                        <Field
                            component={TextField}
                            variant="outlined"
                            name="observations"
                            type="text"
                            fullWidth
                            multiline
                            rows="3"
                            data-cy="observations"
                        />

                        <p className={styles.SectionHeader}>
                            Estado
			</p>

                        <Field
                            name="state"
                            as={MultiStateToggle}
                            options={stateOptions}
                        />


                        <p className={styles.SectionHeader}>
                            Dates
			</p>

                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Commited Date
					</TableCell>
                                        <TableCell>
                                            <Field
                                                component={DatePicker}
                                                name="commit_date"
                                                autoOk
                                                clearable
                                            />
                                        </TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Deadline Date
					</TableCell>
                                        <TableCell>
                                            <Field
                                                component={DatePicker}
                                                name="deadline"
                                                autoOk
                                                clearable
                                            />
                                        </TableCell>

                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Completed Date
					</TableCell>
                                        <TableCell>
                                            <Field
                                                component={DatePicker}
                                                name="completed_date"
                                                autoOk
                                                clearable
                                            />
                                        </TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>


                        <div style={{ float: "right" }}>

                            <Button
                                variant="contained"
                                color="primary"
                                disabled={Boolean(isSubmitting || !isValid)}
                                onClick={submitForm}
                                data-cy="submit"
                            >
                                Atualizar
			    </Button>
                        </div>

                    </Form>
                )}
            </Formik>
        </Grid >
    );
};

export default EditGoal;
