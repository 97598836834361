import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const ObservationsInput = (props: any) => {

    if (props.hide) {
        return null;
    }

    return (
        <FormControl fullWidth>
            <TextField
                margin="dense"
                id="observations"
                name="observations"
                label="Observations"
                defaultValue={props.initial}
                onChange={props.set}
                type="text"
                multiline
            />
        </FormControl>
    );
};

export default ObservationsInput;
