import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { useGuestRedirection } from '../../services/Hooks';
import { CURRENT_PLANNING } from '../../services/Queries';
import { ExpandedPlanning } from '../PlanningTable/PlanningTable';

const Dashboard: React.FC = () => {

    useGuestRedirection();

    const { loading, data, refetch } = useQuery(CURRENT_PLANNING);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <ExpandedPlanning planning={data.current_planning} refetch={refetch} />
    );
};

export default Dashboard;
