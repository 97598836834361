import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';
import { useCreate_GoalMutation } from '../../generated/graphql';
import { useChangeHandler } from '../../services/Hooks';
import { GET_GOALS } from '../../services/Queries';
import styles from './CreateGoal.module.scss';

const CreateGoal = ({ id, onComplete }: { id?: any, onComplete?: any }) => {

    const [createGoal] = useCreate_GoalMutation()
    const [goal, setGoal] = useState({ deadline: null, commit_date: null });
    const changeHandler = useChangeHandler(goal, setGoal);

    const handleDateChange = (date: any, field: any) => {
        setGoal({ ...goal, [field]: date });
    };

    const handleSubmit = () => {
        // @ts-ignore
        createGoal({
            variables: { ...goal },
            refetchQueries: [{
                query: GET_GOALS
            }]
        }).then(onComplete);
    };

    return (
        <div className={styles.CreateGoal}>
            <Grid container direction="column">
                <p>ID: {id}</p>
                <TextField name="name" placeholder="name" onChange={changeHandler} autoFocus />
                <TextField name="description" placeholder="description" multiline onChange={changeHandler} />

                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="DD/MM/yyyy"
                        margin="normal"
                        label="Deadline"
                        value={goal.deadline}
                        onChange={date => handleDateChange(date, 'deadline')}
                    />

                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="DD/MM/yyyy"
                        margin="normal"
                        label="Commit Date"
                        value={goal.commit_date}
                        onChange={date => handleDateChange(date, 'commit_date')}
                    />

                </MuiPickersUtilsProvider>

                <Button color="primary" variant="contained" onClick={handleSubmit}>Create Goal</Button>
            </Grid>
        </div>
    );
};

export default CreateGoal;
