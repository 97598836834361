import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { useGuestRedirection } from '../../services/Hooks';
import { GET_PLANNING } from '../../services/Queries';
import Loading from '../Loading/Loading';
import PlanningTable from '../PlanningTable/PlanningTable';

const PlanningDetails = (props: any) => {

    useGuestRedirection();

    const id = props.match.params.id;
    const { loading, data } = useQuery(GET_PLANNING, { variables: { id } });

    if (loading) {
        return <Loading />;
    }

    return (
        <PlanningTable key={data.getPlanning.id} planning={data.getPlanning} />
    );
};

export default PlanningDetails;
