
export const capitalize = (string: any) => {
    return string.charAt(0).toUpperCase() + string.substring(1);
}

export const errorParser = (response: any) => {

    let errors: any = {}

    const key = Object.keys(response.data)[0];
    const e = response.data[key].errors;

    if (e) {
        e.map((error: any) => errors[error.field] = error.message[0]);
    }

    return errors;
}
