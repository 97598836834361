import gql from 'graphql-tag';

// FRAGMENTS

const GOAL_ATTRIBUTES = gql`
fragment goalAttributes on Goal {
    id
    name
    description
    state
    commit_date
    completed_date
    deadline
    observations
    deliverable
    created
    updated
}
`;

const PLANNING_ATTRIBUTES = gql`
fragment planningAttributes on Planning {
    id
    week
    year
    allocated
    executed
    max_allocation
    state
    goal_completion
    notes
    evaluation
}
`;

// MUTATIONS


export const CREATE_GOAL = gql`
mutation create_goal($name: String!, $description: String, $deadline: Datetime, $commit_date: Datetime) {
    create_goal(name: $name, description: $description, deadline: $deadline, commit_date: $commit_date) {
        ...goalAttributes
    }
}
${GOAL_ATTRIBUTES}
`;


export const CREATE_PLANNING = gql`
mutation createPlanning($week: Int!, $year: Int!, $type: String!, $max_allocation: Int!) {
    createPlanning(week: $week, year: $year, type: $type, max_allocation: $max_allocation) {
        id
        week
        year
        type
        max_allocation
    }
}
`;


export const CREATE_PLANNING_TASK = gql`
mutation CreatePlanningTask($allocated: Int!, $task: Int!, $planning: Int!, $observations: String) {
    createPlanningTask(allocated: $allocated, task: $task, planning: $planning, observations: $observations) {
        id
        allocated
        executed
        task {
            id
            name
            state
            project {
                id
                name
            }
        }
        planning {
            id
        }
    }
}
`;

export const UPDATE_GOAL = gql`
mutation UpdateGoal($id: Int!, $name: String, $description: String, $deadline: Datetime, $commit_date: Datetime, $completed_date: Datetime, $observations: String, $deliverable: String, $state: GoalState) {
    updateGoal(id: $id, name: $name, description: $description, deadline: $deadline, commit_date: $commit_date, completed_date: $completed_date, observations: $observations, deliverable: $deliverable, state: $state) {
        ...goalAttributes
    }
}
${GOAL_ATTRIBUTES}
`;


export const UPDATE_PLANNING_TASK = gql`
mutation UpdatePlanningTask($id: Int!, $allocated: Int, $state: String, $observations: String, $is_bookmarked: Boolean, $is_goal: Boolean, $priority: Int) {
    updatePlanningTask(id: $id, allocated: $allocated, state: $state, observations: $observations, is_bookmarked: $is_bookmarked, is_goal: $is_goal, priority: $priority) {
        id
        allocated
        state
        observations
        is_bookmarked
        is_goal
        priority
    }
}
`;

export const DELETE_PLANNING_TASK = gql`
mutation DeletePlanningTask($id: Int!) {
    deletePlanningTask(id: $id)
}
`;

// QUERIES

export const GET_GOALS = gql`
query GetGoals($state: GoalState, $fullSearch: String) {
    get_goals(state: $state, full_search: $fullSearch) {
        count
        next
        previous
        results {
            ...goalAttributes
        }
    }
}
${GOAL_ATTRIBUTES}
`;


export const GET_PLANNING = gql`
query getPlanning($id: ID!) {
    getPlanning(id: $id) {
        ...planningAttributes
    }
}
${PLANNING_ATTRIBUTES}
`;




export const GET_PLANNINGS = gql`
query plannings {
    plannings {
        ...planningAttributes
    }
}
${PLANNING_ATTRIBUTES}
`;

export const PLANNING_TASKS = gql`
query PlanningTasks($planning: Int, $state_In: String) {
    planning_tasks(planning: $planning, state_In: $state_In) {
        id
        allocated
        executed
        state
        observations
        is_bookmarked
        is_goal
        priority
        task {
            id
            name
            state
            project {
                id
                name
            }
        }
    }
}
`;


export const PROJECTS = gql`
query projects {
    projects {
        id
        name
        state
    }
}
`;

export const ACTIVE_PROJECTS = gql`
query activeProjects {
    active_projects {
        id
        name
        state
    }
}
`;




export const TASKS = gql`
query Tasks($project: Int){
    tasks(project: $project, state_In: "CREATED,STOPPED,EXECUTING,ESTIMATED", user: 1) {
        id
        name
        project {
            id
            name
       }
    }
}
`;

export const TASK = gql`
query Task($id: Int!) {
    task(id: $id) {
        id
        name
        state
        description
    }
}
`;

export const CURRENT_PLANNING = gql`
query currentPlanning{
    current_planning {
        ...planningAttributes
    }
}
${PLANNING_ATTRIBUTES}
`;

export const RECURRENT_TASKS = gql`
query RecurrentTasks {
    recurrent_tasks {
        id
        name
        estimate
        recurrence
        notify
        project {
            name
        }
        user {
            name
        }
    }
}
`

export const RECURRENT_TASK = gql`
query RecurrentTask($id: Int!) {
    recurrent_task(id: $id) {
        id
        name
        estimate
        recurrence
        description
        project {
            id
            name
        }
    }
}
`;

export const UPDATE_RECURRENT_TASK = gql`
mutation UpdateRecurrentTask($id: Int!, $name: String, $estimate: Int, $description: String) {
    updateRecurrentTask(id: $id, name: $name, estimate: $estimate, description: $description) {
        id
        name
        estimate
        description
    }
}
`;

export const UPDATE_PLANNING = gql`
mutation updatePlanning($id: ID!, $state: PlanningState, $max_allocation: Int, $notes: String, $evaluation: String){
  updatePlanning(id: $id, state: $state, max_allocation: $max_allocation, notes: $notes, evaluation: $evaluation) {
    id
    week
    year
    state
    max_allocation
  }
}
`;


export const AUTHENTICATE = gql`
mutation authenticate($email: String, $password: String) {
    authenticate(email: $email, password: $password) {
        __typename
        ... on ValidationError {
            message
        }
        ... on AuthenticatePayload {
            token
            user {
                id
                name
            }
        }
    }
}
`;
